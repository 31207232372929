$tBlack: rgba(0, 0, 0, 0.4);

.workExperience {
  background: url('/assets/images/banner2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 50px;
  //text-align: center;
  h2 {
    color: $white;
    text-align: center;
    margin-bottom: 50px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $green;
    opacity: .6;
  }

  .experienceHeading {
    //line-height: 80px;
    padding-left: 80px;
    padding-bottom: 30px;
    font-size: 16px;
    height: 100%;
    font-weight: bold;
    color: $textBlack;
    position: relative;
    &:before {
      left: 20%;
      width: 5px;
      height: 100%;
      border-left: 5px solid $tBlack;
      position: absolute;
      display: block;
      content: "";
    }
    &:after {
      top: 45%;
      left: 20%;
      right: 20px;
      width: 78%;
      border-bottom: 5px solid $tBlack;
      position: absolute;
      display: block;
      content: "";
    }
    span {
      position: absolute;
      left: 18%;
      top: 39.5%;
      background-color: $white;
      border: 4px solid $tBlack;
      z-index: 1;
      height: 22px;
      width: 22px;
      display: inline-block;
      border-radius: 99px;
    }
    p {
      margin-left: 20px;
      position: relative;
      left: -50px;
      top: 35px;
    }
  }

  .timeLineHeading {
    display: none;
  }

  @media only screen and (min-width: 576px) {
    .timeLineHeading {
      display: block;
    }
  }
  @media only screen and (min-width: 768px) {
    .experienceHeading {
      p {
        position: relative;
        left: 0;
      }
    }
  }

  .experienceContainer {
    position: relative;
    border-radius: 5px;
    padding: 5px 10px 10px 20px;
    background-color: rgba(255, 255, 255, 0.6);
    color: $textBlack;
    margin-bottom: 10px;
    h4 {
      font-size: 18px;
    }
    p {

    }
  }

  .bottomDivider {
    position:absolute;
    bottom: 0;
    transform: rotate(180deg);
  }
  .bottomDivider2 {
    position:absolute;
    bottom: 0;
    left: 50%;
    transform: rotateX(180deg);
  }

  .technologies {
    color: #8c8c8c;
    font-size: 14px;
  }

}