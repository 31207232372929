.aboutMe {
  background-image: url('/assets/images/banner1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position:relative;
  text-align: center;
  background-position: center;
  h2 {
    color: $white;
    margin-top: 0;
  }
  h4 {
    color: $white;
    color: $white;
    line-height: 25px;
  }
  h3 {
    color: $white;
    padding-top: 30px;
    line-height: 25px;
  }
  .aboutMeText {
    color: $white;
    line-height: 25px;
  }
  .container {
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: .7;
  }

  .notableSkill {
    color: white;
    p {
      display: inline-block;
      margin-right: 13px;
    }
    i {
      color: $white;
      margin-right: 7px;
    }
    .empty {
      opacity: 0.3;
      color: #626262;
    }
  }
  @media only screen and (min-width : 768px) {
    .notableSkill {
      i {
        margin-right: 3px;
      }
      .aboutMeText {
        padding-top: 50px;
      }
    }
    h3 {
      //margin-top: 70px;
    }
  }
  @media only screen and (min-width : 992px) {
    h3 {
      //margin-top: 100px;
    }
  }
}