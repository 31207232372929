.logoSplitContainer {
  position:relative;
  background-color: #353536;
  height:100vh;
  display: flex;
  justify-content: center;

  .pageHeading {
    z-index: 2;
    position: absolute;
    top: 50px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 20px;
    text-align: center;
    @media only screen and (min-width : 670px) {
      top: 130px;
    }
  }

  i {
    color: #353536;
    background-color: $white;
    position: absolute;
    bottom: 70px;
    z-index: 2;
    padding: 10px;
    border-radius: 3px;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
    cursor: pointer;
  }


  @media only screen and (min-width : 1000px) {
    svg {
      padding-bottom: 200px;
    }
  }
}

@keyframes bounce {
  0% {transform: translateY(0px);}
  45% {transform: translateY(20px);}
  100% {transform: translateY(0px);}
}