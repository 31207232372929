.projects {
  color: $textBlack;
  img {
    width: 100%;
    height: 100%;
  }
  .filters {
    text-align: center;
  }
  .piece {
    position: relative;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    .skillsLine {
      position: absolute;
      top: 30px;
      opacity: 0;
      color: $white;
      width: 100%;
      text-align: center;
      h4 {
        font-weight: 400;
        font-size: 18px;
        color: $white;
        margin-bottom: 7px;
      }
      span {
        color: $green;
      }
    }
    a {
      position: absolute;
      bottom: 70px;
      opacity: 0;
      font-weight: 400;
      font-size: 18px;
      z-index: 3;
      border-radius: 3px;
      border: 3px solid $green;
      padding: 10px 20px;
      cursor: pointer;
      color: $white;
      text-decoration: none;
    }
    &:before {
      animation: fadeInPartWay .5s ease both;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      content: '';
      transition: all .5s ease;
    }
    &:hover {
      &:before {
        opacity: 0.9;
        background: $black;
      }
      .skillsLine {
        animation: fadeInDown .5s ease both;
        opacity: 1;
      }
      a {
        animation: fadeInUp .5s ease both;
        opacity: 1;
      }
    }
  }
  .projectFilterButton {
    display: inline-block;
    //margin-right: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    border-radius: 3px;
    padding: 10px 20px;
    text-decoration: none;
  }
  .projectFilterButton.current {
    border: 3px solid $green;
  }

  @media (max-width: 575px) {
    .container {
      padding-bottom: 0;
    }
  }

}

@keyframes fadeInDown {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInPartWay {
  from {
    opacity: 0;
  }
  to {
    opacity: .8;
  }
}