$white: #EAEAEA;
$green: #BEFF07;
$black: #3c3c3e;
$textBlack: #666666;

@import 'normalize';
@import 'boot-grid';
@import url('https://fonts.googleapis.com/css?family=Nunito:200,300,400,700');
@import 'adearlife/adearlife';

* {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;

}

.whiteText {
  color: $white;
  line-height: 25px;
}

.container {
  padding: 30px 5px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .container {
    padding: 40px 5px;
  }
}



h1, h2, h3, h4, h5 ,h6 {
  letter-spacing: 7px;
}

h3 {
  letter-spacing: 1px;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 20px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 0;
}

@media (min-width: 767px) {
  .container {
    padding: 120px 0;
  }
  h2 {
    margin-bottom: 50px;
  }
}

.randomBox {
  height: 200px;
  width: 200px;
  background-color: pink;
}

