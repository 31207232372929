.footer {
  background-color: $black;
  p {
    margin: 0;
    color: #6b7480;
  }
  .iconContainer {
    text-align: right;
    i {
      color: #6b7480;
      font-size: 29px;
      padding-right: 22px;
      position:relative;
      top: -7px;
    }
    i:hover {
      color: $green;
      cursor: pointer;
    }
  }
}